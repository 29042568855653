<template>
    <div>
        <TableList
            :columns="columns"
            :get_table_list="this.get_table_list"
            :slot_table_list="['operation','status']"
            :is_page="false"
            ref="list"
			@list_after="list_after"
        >
            <template slot="left_btn">
                <a-button class="float_left" v-auth="'change'" @click="add()" type="primary">添加菜单</a-button>
            </template>
            <template slot="status" slot-scope="data">
                <status :value="data.text"></status>
            </template>

            <template slot="operation" slot-scope="data">
                <a v-auth="'change'" @click="edit(data.record)">编辑</a>
                <a-divider type="vertical" />

                <a-popconfirm title="您确定要删除吗?" v-auth="'delete'" @confirm="del(data.record)">
                    <a>删除</a>
                </a-popconfirm>
            </template>
        </TableList>

        <EditPop
            ref="edit_pop"
            @handleSubmit="submit"
            :form_data="form_data"
            :visible.sync="visible"
        ></EditPop>
    </div>
</template>

<script>
// @ is an alias to /src
import EditPop from "@/components/EditPop";
import Form from "@/components/Form";
import { AppMenuList, saveAppMenu, delAppMenu } from '@/api/system'
import TableList from "@/components/TableList";

const columns = [
    {
        title: "菜单名称",
        dataIndex: "title",
    },
    {
        title: "ID",
        dataIndex: "id",
    },
    {
        title: "排序",
        dataIndex: "sort",
    },
    {
        title: "链接",
        dataIndex: "path",
    },
    {
        title: "状态",
        dataIndex: "status",
        scopedSlots: {
            customRender: "status"
        }
    },

    {
        title: "操作",
        width: 150,
        scopedSlots: {
            customRender: "operation"
        }
    },
];


export default {
    name: "Index",
    components: {
        EditPop,
        TableList,
        Form
    },
    data() {
        return {
            get_table_list: AppMenuList,
            config: this.$config,
            status_list: this.$config.status_list,
            columns,
            visible: false,
            form_data: {
                title: '添加',
                list: [
                    {
                        type: 'text',
                        name: 'id',
                        hidden: true,
                        options: {},
                    },
                    {
                        type: "tree-select",
                        name: "pid",
                        title: "上级菜单",
                        list: [],
                        options: {
                            initialValue: 0
                        }
					},
					{
                        type: "text",
                        name: "title",
                        title: "菜单名称",
                        options: {}
                    },
                    {
                        type: "text",
                        name: "path",
                        title: "菜单链接",
                        options: {}
                    },
                    {
                        type: "upload",
                        name: "icon",
                        title: "图标",
                        options: {}
					},
					{
                        type: "text",
                        name: "sort",
                        title: "排序",
                        options: {}
                    },
					{
                        type: "radio",
                        name: "status",
                        title: "状态",
                        options: {
                            initialValue: 1
                        },
                        list: this.$config.status_list
                    },
					{
                        type: "text",
                        name: "desc",
                        title: "菜单描述",
                        options: {}
                    }
                ]
            }
        };
    },
    methods: {
		list_after() {
            let list = this.$method.get_depart_list(this.$refs.list.list, 'children', 'id', 'title');
            this.form_data.list = this.$method.set_form_list(this.form_data.list, 'pid', 'treeData', [{ key: 0, value: 0, title: '顶部菜单' }, ...list]);
        },
        add() {
			this.form_data.title = "添加"
			
			this.form_data.list.forEach(item => {
				 if (item.type == 'upload') {
					item.options.initialValue = [];
				}else if(item.name == 'pid'){
					item.options.initialValue = 0;
				} else {
					item.options.initialValue = "";
				}
			})

            this.visible = true;
        },
        edit(record) {
			this.form_data.title = "编辑"
			
            this.form_data.list.forEach(item => {
				 if (item.type == 'upload') {
					item.options.initialValue = [{
						uid: record[item.name],
						name: record[item.name],
						status: 'done',
						url: record[item.name]
					}];
				} else {
					item.options.initialValue = record[item.name];
				}
				
			})
			
            this.visible = true;
        },
        del(record) {
            delAppMenu({
                data: {
                    id: record.id
                },
                info: true,
            }).then(res => {
                this.$refs.list.get_list();
            })
        },
        submit(e) {
            let data = e.values;
            if (!data.id) {
                data.id = '';
            }
            saveAppMenu({
                data: {
                    ...data
                },
                info: true,
            }).then(res => {
                this.visible = false;
                this.$refs.list.get_list();
            })
        },
    }
};
</script>

<style lang="less">
@import url("../../assets/less/app.less");
</style>